<template>
  <div
    class="bid-price"
    v-if="showPay"
    @click="payClear"
  >
    <div
      class="common-bid-price"
      @click.stop="kong"
    >
      <div class="common-title item-flex-center fw500">
        请选择保证金类型
        <div
          class="close"
          @click="payClear"
        >
          <img
            src="https://artimg2.artart.cn/image/20220129/37face345e08861294c4efb7895d443e.png"
            alt=""
          >
        </div>
      </div>
      <div class="price-list">
        <div
          class="item"
          :style="payType == 1 ? { borderColor: colorData.specialColor, color: colorData.specialColor } : {}"
          v-if="albumData"
          @click="changeType(1)"
        >专场保证金：{{ albumData.bond_price }}<img
            class="bg nochange"
            :style="{ background: colorData.specialColor }"
            src="https://artimg2.artart.cn/image/20220212/120b16b5bfab7e6d940bfed77d123561.png"
            v-if="payType == 1"
          ></div>
        <div
          class="txt ft12 color-gray"
          v-if="albumData"
        >可以对本场的{{ albumData.auction_num }}件拍品出价</div>
        <div
          class="item"
          v-if="auctionData"
          :style="payType == 2 ? { borderColor: colorData.specialColor, color: colorData.specialColor } : {}"
          @click="changeType(2)"
        ><template v-if="auctionData.lot">LOT{{ auctionData.lot }}</template>单件保证金：{{ auctionData.bond_price }}<img
            class="bg nochange"
            :style="{ background: colorData.specialColor }"
            src="https://artimg2.artart.cn/image/20220212/120b16b5bfab7e6d940bfed77d123561.png"
            v-if="payType == 2"
          >
        </div>
        <div
          class="txt ft12 color-gray"
          v-if="auctionData"
        >只可对当前此件拍品出价</div>
      </div>
      <div
        class="use-account item-flex-center"
      >
        <div class="l">使用余额支付</div>
        <div class="c">可用余额 <span>{{ formatPriceNumber(account.balance, 2) }}</span></div>
        <div class="r">
          <van-switch
            v-model="isUseAccountBalance"
            :active-color="colorData.specialColor"
            size="38"
            :active-value="1"
            :inactive-value="0"
          />
        </div>
      </div>
      <div class="btns">
        <div
          class="btn"
          :style="rightBtnStyle"
          @click="payBondMoney"
        >确认</div>
        <div class="txt ft12 color-gray" @click="goRuleShow">出价即表示同意《{{ mer_name }}交易服务隐私协议》</div>
      </div>
    </div>

    <div class="step-modal" v-if="showWithdrawal" @click.stop>
			<div class="content">
				<div class="t">
					<div class="title">支付密码</div>
					<div class="close-img" @click.stop="cancelWithdrawal">
						<img src="https://artimg2.artart.cn/image/20210225/b072895ef3d613f4524b92252da90e5b.png" mode="" />
					</div>
				</div>
				<div class="c">余额支付 <span>￥{{payType == 1?albumData.bond_price:auctionData.bond_price}}</span></div>
				<div class="b">
					<div class="item-num"><span class="p" v-if="password[0]"></span><span class="xx" v-if="passwordCount == 0"></span></div>
					<div class="item-num"><span class="p" v-if="password[1]"></span><span class="xx" v-if="passwordCount == 1"></span></div>
					<div class="item-num"><span class="p" v-if="password[2]"></span><span class="xx" v-if="passwordCount == 2"></span></div>
					<div class="item-num"><span class="p" v-if="password[3]"></span><span class="xx" v-if="passwordCount == 3"></span></div>
					<div class="item-num"><span class="p" v-if="password[4]"></span><span class="xx" v-if="passwordCount == 4"></span></div>
					<div class="item-num"><span class="p" v-if="password[5]"></span><span class="xx" v-if="passwordCount == 5"></span></div>
				</div>
			</div>
		</div>
		<div class="common-keyboard" v-if="showWithdrawal" @click.stop>
			<div class="item-btn" @click.stop="clickNum(1)" data-key="1">1</div>
			<div class="item-btn" @click.stop="clickNum(2)" data-key="2">2</div>
			<div class="item-btn" @click.stop="clickNum(3)" data-key="3">3</div>
			<div class="item-btn" @click.stop="clickNum(4)" data-key="4">4</div>
			<div class="item-btn" @click.stop="clickNum(5)" data-key="5">5</div>
			<div class="item-btn" @click.stop="clickNum(6)" data-key="6">6</div>
			<div class="item-btn" @click.stop="clickNum(7)" data-key="7">7</div>
			<div class="item-btn" @click.stop="clickNum(8)" data-key="8">8</div>
			<div class="item-btn" @click.stop="clickNum(9)" data-key="9">9</div>
			<div class="item-kong"></div>
			<div class="item-btn" @click.stop="clickNum(0)" data-key="0">0</div>
			<div class="item-del" @click.stop="clickNum('del')" data-key="del"></div>
		</div>

		<div class="step-modal" v-if="showWithdrawalError" @click.stop>
			<div class="content">
				<div class="t">
					<div class="title">支付密码错误</div>
					<div class="close-img" @click="cancelWithdrawalError">
						<image src="https://artimg2.artart.cn/image/20210225/b072895ef3d613f4524b92252da90e5b.png" mode=""></image>
					</div>
				</div>
				<div class="c1">支付密码错误，提现已经自动冻结24小时，可修改支付密码。</div>
				<div class="b-button">
					<div class="b-btn" @click="cancelWithdrawalError">取消</div>
					<div class="b-btn" @click="editPassword">去修改</div>
				</div>
			</div>
		</div>

		<div class="step-modal" v-if="showWithdrawalError1" @click.stop>
			<div class="content">
				<div class="t">
					<div class="title">操作提示</div>
					<div class="close-img" @click="cancelWithdrawalError1">
						<image src="https://artimg2.artart.cn/image/20210225/b072895ef3d613f4524b92252da90e5b.png" mode=""></image>
					</div>
				</div>
				<div class="c1">您还没有设置支付密码</div>
				<div class="b-button">
					<div class="b-btn" @click="cancelWithdrawalError1">取消</div>
					<div class="b-btn" @click="editPassword1">去设置</div>
				</div>
			</div>
		</div>

  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, toRefs, computed, watch } from "vue";
import { payAction, isWeiXin, formatPriceNumber } from "@/utils/util";
import { Toast, Switch } from "vant";
import { payAlbumBond, payAuctionBond } from "@/api/auction";
import { useRouter } from 'vue-router';
import { getInfo } from "@/api/user";
// import { formatPriceNumber } from "@/utils/util";

export default {
  components: {
    [Switch.name]: Switch,
  },
  props: {
    showPay: Boolean,
    albumData: [Object, String],
    auctionData: [Object, String],
    account: Object,
  },
  setup(props, content) {
    const { albumData, auctionData, showPay, account } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const isUseAccountBalance = ref(false);

    const payType = ref(1);
    let payLock = false;

    const password = ref([]);
    const passwordCount = ref(0);
    const showWithdrawal = ref(false);
    const showWithdrawalError = ref(false);
    const submitOrderData = ref({});
    const showWithdrawalError1 = ref(false);
    const isSetPayPass = ref(false);
    watch(showPay, (val) => {
      if (val && albumData.value) {
        payType.value = 1;
      } else if (val && auctionData.value) {
        payType.value = 2;
      }
      if (!account.value.balance) {
        isUseAccountBalance.value = false;
      }
    });

    watch(isUseAccountBalance, val => {
      if (val && !account.value.balance) {
        isUseAccountBalance.value = false;
      }else if(val) {
        changeUseAccountAction();
      }
    })

    // 获取color样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    // 选择支付保证金类型
    const changeType = (type) => {
      if (payType.value !== type) {
        payType.value = type;
      }
    };
    // 商户名称
    const mer_name = computed(() => {
      return store.getters.mer_name;
    });

    // 交保证金
    const payBondMoney = () => {
      let action = payAlbumBond;
      let data = {
        isUseAccountBalance: isUseAccountBalance.value,
      };
      if (payType.value === 1) {
        data.album_id = albumData.value.album_id;
      } else if (payType.value === 2) {
        action = payAuctionBond;
        data.auction_id = auctionData.value.product_id;
      }
      if (isUseAccountBalance.value) {
        data.pay_type = 'balance';
        if(!password.value || passwordCount.value < 5) {
          if(!isSetPayPass.value) {
            showWithdrawalError1.value = true;
            return;
          }
          confirmWithdrawal();
          return;
        }
        data.pay_pass = password.value.join("");
        cancelWithdrawal();
      }else{
        if(window.uni){
          data.pay_type = 'wechatApp';
        }else{
          // if (isWeiXin()) {
          data.pay_type = "wechat_h5";
          // }
        }
      }
        
      if (payLock) return false;
      payLock = true;

      action(data)
        .then((res) => {
          // console.log(res.data.data.pay_result.appId)
          if(res.data.status == "balance"){
            Toast("支付成功");
            content.emit("payOk");
            return
          }
          if (res.data.status == 1) {
            Toast("支付成功");
            content.emit("payOk");
          } else if (data.pay_type == "wechat_h5" || data.pay_type == "wechatApp") {
            payAction(
              res.data.result.config,
              () => {
                Toast("支付成功");
                payLock = false;
                content.emit("payOk");
              },
              () => {
                payLock = false;
                Toast("支付失败");
              },
              () => {
                payLock = false;
                Toast("支付失败");
              }
            );
          }
        })
        .catch((message) => {
          Toast(message);
          payLock = false;
        });
    };

    const payClear = () => {
      content.emit("payClear");
    };

    const kong = () => {};

    const goRuleShow = () => {
      // 查看协议
      router.push('/users/agreement?type=2');
    }
    
    getInfo().then(res => {
      isSetPayPass.value = res.data.isSetPayPass;
    })

    const changeUseAccountAction = () => {
      if (payType.value === 1) {
        if (account.value.balance < albumData.value.bond_money) {
          Toast("余额不足");
          isUseAccountBalance.value = false;
        }
      }else if (payType.value === 2) {
        if (account.value.balance < auctionData.value.bond_money) {
          Toast("余额不足");
          isUseAccountBalance.value = false;
        }
      }
    }

    const clickNum = (e) => {
      let key = e;
      if (key != 'del') { 
        if (password.value.length < 5) {
          password.value.push(key);
          passwordCount.value++;
        } else if (password.value.length == 5) {
          password.value.push(key);
          passwordCount.value++;
          // this.cancelWithdrawal();  
          payBondMoney();
        }
      } else {
        if (password.value.length > 0) {
          password.value.splice(password.value.length - 1, 1);
          passwordCount.value--;
        }
      }
    }
    const cancelWithdrawalError = () => {
      showWithdrawalError1.value = false;
    }
    const confirmWithdrawal = () => {
      password.value = [];
      passwordCount.value = 0;
      showWithdrawal.value = true
    }
    const cancelWithdrawal = () => {
      showWithdrawal.value = false;
      password.value = [];
      passwordCount.value = 0;
    }
    const editPassword = () => {
      cancelWithdrawalError();
      router.push('/pages/pay/password');
    }
    const cancelWithdrawalError1 = () => {
      showWithdrawalError1.value = false;
    }
    const editPassword1 = () =>{
      cancelWithdrawalError1();
      content.emit("payClear");
      router.push('/pages/pay/password');
    }
    return {
      payType,
      colorData,
      rightBtnStyle,
      leftBtnStyle,
      isUseAccountBalance,
      mer_name,

      payBondMoney,
      changeType,
      payClear,
      kong,
      formatPriceNumber,
      goRuleShow,

      password,
      passwordCount,
      showWithdrawal,
      showWithdrawalError,
      submitOrderData,
      showWithdrawalError1,
      isSetPayPass,
      changeUseAccountAction,
      editPassword,
      editPassword1,
      clickNum,
      cancelWithdrawal
    };
  },
};
</script>

<style lang="scss" scoped>
.bid-price {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  z-index: 1000;

  .common-bid-price {
    width: 100%;
    background: #fff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding-bottom: 60px;

    .common-title {
      position: relative;
      padding-top: 22px;
      font-size: 32px;
      min-height: 60px;

      img {
        margin-left: 32px;
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 50%;
        margin-right: 16px;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 28px;
        padding-right: 32px;

        img {
          width: 48px;
          height: 48px;
          margin-right: 0;
        }
      }
    }
  }

  .price-list {
    padding-top: 8px;
    padding-right: 20px;

    .item {
      flex: 1;
      margin-left: 20px;

      height: 80px;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #dddddd;
      font-weight: 500;
      font-size: 32px;
      line-height: 80px;
      text-align: center;

      position: relative;
      margin-top: 28px;

      .nochange {
        position: absolute;
        right: 6px;
        top: 6px;
        width: 24px;
        height: 24px;
      }
    }
    .txt {
      text-align: center;
      margin-top: 10px;
      padding-left: 20px;
    }
  }
  .btns {
    padding-top: 36px;
    .btn {
      margin: 0 20px;
      line-height: 80px;
      text-align: center;
    }

    .txt {
      margin: 28px;
      text-align: center;
    }
  }
}

.use-account {
  margin: 20px 20px 0 20px;
  .l {
    font-size: 28px;
    font-weight: 500;
  }

  .c {
    flex: 1;
    margin-left: 14px;
    font-size: 24px;
    color: #999;
  }

  .van-switch {
    display: block;
  }
}


.common-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(21, 21, 21, 0.85);
  display: flex;
  flex-wrap: wrap;
  z-index: 100000;
  padding-bottom: 50px;
}

.common-keyboard .item-btn,
.common-keyboard .item-kong {
  width: 234px;
  height: 92px;
  background: #646465;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  margin-left: 12px;
  margin-top: 14px;
  text-align: center;
  line-height: 92px;
  font-size: 50px;
  color: #fff;

}

.common-keyboard .item-btn:active,
.common-keyboard .item-kong:active {
  background: #999999;
}

.common-keyboard .item-kong {
  background: none;
  box-shadow: 0 0 0 0;

}

.common-keyboard .item-kong:active {
  background: none;
}

.common-keyboard .item-del {
  width: 234px;
  height: 92px;
  margin-left: 12px;
  margin-top: 14px;
  background: url(https://artimg2.artart.cn/image/20210225/e5dcc959cf3330b7a627c689e37d415c.png) no-repeat center center;
  background-size: 48px 36px;
}

.common-keyboard-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 899;
}

.step-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-modal .content {
  width: 576px;
  background: #fff;
  border-radius: 12px;
  margin-top: -100px;
}

.step-modal .t {
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 36px;
  font-weight: bold;
  border-bottom: 2px solid #f7f7f7;
}

.step-modal .step {
  width: 40px;
  height: 40px;
  border: 2px solid #000;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  margin-right: 10px;
}

.step-modal .close-img {
  position: absolute;
  right: 30px;
  top: 22px;
}

.step-modal image {
  width: 32px;
  height: 32px;
}

.step-modal .c {
  margin: 40px;
  font-size: 28px;
  text-align: center;
  color: #999;
}

.step-modal text {
  font-size: 36px;
  color: #000;
  margin-left: 10px;
}

.step-modal .c1 {
  margin: 40px;
  font-size: 28px;
}

.step-modal .b {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-bottom: 100px;
}

.step-modal .item-num {
  height: 78px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #ddd;
  margin-left: 22px;
}

.step-modal .item-num:first-child {
  margin-left: 0;
}

.step-modal .xx {
  height: 40px;
  width: 2px;
  background: #ccc;
  animation: cursors 1s linear 0s infinite;
}

.step-modal .p {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  background: #000;
}

.step-modal .b-button {
  height: 80px;
  display: flex;
  align-items: center;
  margin: 0 40px 40px 40px;
}

.step-modal .b-btn {
  flex: 1;
  margin-left: 40px;
  text-align: center;
  line-height: 80px;
  height: 80px;
  font-size: 28px;
  border-radius: 40px;
  background: #000;
  color: #fff;
}

.step-modal .b-btn:first-child {
  margin-left: 0;
  border: 2px solid #000;
  color: #000;
  background: none;
}

@keyframes cursors {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
</style>